<template>
  <div>
    <template v-if="$options.components['advertisement-fashion-banner']">
      <advertisement-fashion-banner />
    </template>

    <div class="container">
      <h1 class="heading-category">Our Category</h1>
      <sections-slider
        v-if="category && category.length"
        :list="category"
        :theme="theme"
        subTitle="Our Category"
        :hasInnerBackground="true"
        :hasButton="false"
        :withSlider="true"
      >
        <template slot="default" slot-scope="{ listItem }">
          <div class="item-box">
            <items-fashion-item
              :itemLogo="true"
              :product="listItem"
              :incrementDecrementKey="false"
              :marketRoute="{
                name: `category-listing`,
                params: {
                  data: listItem.name,
                },
              }"
            ></items-fashion-item>
          </div>
        </template>
      </sections-slider>
    </div>

    <template v-if="$options.components['advertisement-loading']">
      <advertisement-loading v-if="isLoading" />
    </template>
    <!-- <sections-slider
        :list="trending"
        subTitle="Trending"
        :showTitle="true"
        :hasBackground="false"
        :hasButton="true"
        class="suggested-slider"
        :withSlider="true"
        :viewMorePage="{
          name: `trending-list`,
        }"
      >
        <template slot="default" slot-scope="{ listItem }">
          <div class="item-box">
            <items-item
              :itemNoBg="true"
              :product="listItem"
              :incrementDecrementKey="true"
              class="suggester-div"
            >
            </items-item>
          </div>
        </template>
      </sections-slider> -->
    <div
      class="bg-colors mb-5"
      v-for="marketItem in Object.keys(marketDataList)"
      :key="marketItem"
    >
      <sections-slider
        v-if="marketDataList[marketItem].length > 0"
        :list="marketDataList[marketItem]"
        :theme="theme"
        :subTitle="marketItem"
        :hasButton="true"
        :onlyTitle="false"
        :showTitle="true"
        :itemSubTitle="true"
        :hasBackground="false"
        class="suggested-slider"
        :withSlider="true"
        :viewMorePage="{
          name: `category-listing`,
          params: {
            data: marketItem,
          },
        }"
      >
        <template slot="default" slot-scope="{ listItem }">
          <div class="item-box">
            <items-fashion-item
              :itemNoBg="true"
              :product="listItem"
              :incrementDecrementKey="true"
              class="suggester-div"
            >
            </items-fashion-item>
          </div>
        </template>
      </sections-slider>
    </div>
    <b-container>
      <template v-if="$options.components['advertisement-fashion-middle']">
        <advertisement-fashion-middle />
      </template>
    </b-container>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "fashionMarket",
  data() {
    return {
      isLoading: false,
      theme: {},
      marketName: "",
    };
  },
  computed: {
    ...mapState({
      currentPosition: (state) => state.location.currentPosition,
      marketDataList: (state) => state.fashionMarket.marketDataList,
      marketList: (state) => state.fashionMarket.marketList,
      category: (state) => state.fashionMarket.category,
      trending: (state) => state.fashionMarket.trending,
      marketListData: (state) => state.fashionMarket.marketListData,
    }),
  },

  async mounted() {
    this.isLoading = true;
    await this.$store.dispatch("fashionMarket/fetchAllMarket");
    const marketId = this.marketList.filter((e) => {
      if (e.name == "Clothes & Fashion") {
        this.theme = e.theme;
        this.$store.commit("theme/updateTheme", this.theme);
        if (e.name == "Clothes & Fashion") {
          this.marketName = "Fashion";
        }
        this.$store.commit("theme/updateName", this.marketName);
        return e._id;
      }
    });
    await this.$store.dispatch(
      "fashionMarket/fetchfashionMarketCategory",
      marketId[0]._id
    );
    await this.$store.dispatch(
      "fashionMarket/fetchfashionArrivals",
      this.currentPosition
    );
    this.isLoading = false;
  },

  created() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  },
};
</script>

<style scoped>
.heading-category {
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 22px;
  text-transform: capitalize;
  color: #000000;
}
.bg-colors {
  background: #f8f8f8;
}
.view-more-btn {
  background: var(--custom-color) !important;
  border: 1px solid var(--custom-color) !important;
}
.stores-slider .item-box {
  margin: 0 12px;
  width: 208px;
  /* background: #ffffff; */
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
}
.section-slider-head button:hover {
  color: rgb(255, 144, 41);
  background-color: white;
}

.suggested-slider .item-box {
  margin: 0 12px;
  width: 266px;
  display: block;
}
.suggester-div {
  background: #ffffff;
  border: 1px solid #f1f1f1;
  border-radius: 4px;
}

.suggested-slider .suggester-div:hover {
  /* border: 1px solid #272727; */
  box-shadow: 0px 10px 20px 5px rgba(184, 178, 178, 0.5);
}

.marketitem-slider .item-box {
  margin: 0 12px;
  width: 208px;
  display: block;
}
.bg-colors {
  background: #f8f8f8;
}
@media (max-width: 768px) {
  .v-slide-group__next,
  .v-slide-group__prev {
    display: none !important;
  }

  .marketitem-slider .item-box {
    width: 266px;
  }

  .stores-slider .item-box {
    margin: 0 12px;
    width: 136px;
  }
}
.slider-contaner-2 {
  width: 80%;
}
.slider-contaner-1 {
  width: 100%;
}
</style>
