var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.$options.components['advertisement-fashion-banner'] ? [_c('advertisement-fashion-banner')] : _vm._e(), _c('div', {
    staticClass: "container"
  }, [_c('h1', {
    staticClass: "heading-category"
  }, [_vm._v("Our Category")]), _vm.category && _vm.category.length ? _c('sections-slider', {
    attrs: {
      "list": _vm.category,
      "theme": _vm.theme,
      "subTitle": "Our Category",
      "hasInnerBackground": true,
      "hasButton": false,
      "withSlider": true
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var listItem = _ref.listItem;
        return [_c('div', {
          staticClass: "item-box"
        }, [_c('items-fashion-item', {
          attrs: {
            "itemLogo": true,
            "product": listItem,
            "incrementDecrementKey": false,
            "marketRoute": {
              name: "category-listing",
              params: {
                data: listItem.name
              }
            }
          }
        })], 1)];
      }
    }], null, false, 1964206250)
  }) : _vm._e()], 1), _vm.$options.components['advertisement-loading'] ? [_vm.isLoading ? _c('advertisement-loading') : _vm._e()] : _vm._e(), _vm._l(Object.keys(_vm.marketDataList), function (marketItem) {
    return _c('div', {
      key: marketItem,
      staticClass: "bg-colors mb-5"
    }, [_vm.marketDataList[marketItem].length > 0 ? _c('sections-slider', {
      staticClass: "suggested-slider",
      attrs: {
        "list": _vm.marketDataList[marketItem],
        "theme": _vm.theme,
        "subTitle": marketItem,
        "hasButton": true,
        "onlyTitle": false,
        "showTitle": true,
        "itemSubTitle": true,
        "hasBackground": false,
        "withSlider": true,
        "viewMorePage": {
          name: "category-listing",
          params: {
            data: marketItem
          }
        }
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref2) {
          var listItem = _ref2.listItem;
          return [_c('div', {
            staticClass: "item-box"
          }, [_c('items-fashion-item', {
            staticClass: "suggester-div",
            attrs: {
              "itemNoBg": true,
              "product": listItem,
              "incrementDecrementKey": true
            }
          })], 1)];
        }
      }], null, true)
    }) : _vm._e()], 1);
  }), _c('b-container', [_vm.$options.components['advertisement-fashion-middle'] ? [_c('advertisement-fashion-middle')] : _vm._e()], 2)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }